import React from "react";
import { Stack, Box, Text, Link } from "components/Elements";
import { useLocation } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "assets/svg/Arrow.svg";

const PrevNext = ({ pages }) => {
  const location = useLocation();
  const index = pages.findIndex(({ url }) => url === location.pathname);
  const prev = index > 0 ? pages[index - 1] : null;
  const next = index < pages.length - 1 ? pages[index + 1] : null;
  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        {prev && (
          <Link to={prev.url}>
            <Box display="flex" alignItems="center">
              <Box as={ArrowLeft} height="0.8em" marginRight="0.5em" />
              <Text.Body>{prev.title}</Text.Body>
            </Box>
          </Link>
        )}
      </Box>
      <Box>
        {next && (
          <Link to={next.url}>
            <Box display="flex" alignItems="center">
              <Text.Body>{next.title}</Text.Body>
              <Box as={ArrowRight} height="0.8em" marginLeft="0.5em" />
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export const ProgramPageLayout = ({ pages, children }) => (
  <Stack spacing={[6, 6, 10]}>
    {children}
    <PrevNext pages={pages} />
  </Stack>
);
