import React from "react";
import { Text, Stack, FlexGrid } from 'components/Elements';

export const Details = ({ details, leftColumnWidth = 1 / 2, rightColumnWidth = 1 / 2, ...props }) => (
    <Stack spacing={1} {...props}>
        {details.map(({ label, value }, i) => (
            <FlexGrid key={i}>
                <Text.Small fontWeight="500" width={leftColumnWidth}>
                    {label}
                </Text.Small>
                <Text.Small width={rightColumnWidth}>
                    {value}
                </Text.Small>
            </FlexGrid>
        ))}
    </Stack>
)
