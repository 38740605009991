import React from 'react';
import { Box, Stack, Text } from 'components/Elements';
import { Loader } from 'components/Loader';
import { getFullURL } from 'api/api';

const Success = ({ data, itemURL }) => (
    <Stack>
        <Text.Body color="green">Valid!</Text.Body>
        {itemURL && (
            <Box pl={6}>
                {data.items.map(item => (
                    <Test url={itemURL(item)} />
                ))}
            </Box>
        )}
    </Stack>
)
const Test = ({ url, ...props }) => (
    <Stack spacing={1}>
        <a href={getFullURL(url)} target="_blank" rel="noopener noreferrer">
            <Text.Subheading>{getFullURL(url)}</Text.Subheading>
        </a>
        <Loader url={url} Render={Success} {...props}></Loader>
    </Stack>
)

export const APITest = () => (
    <Stack p={2}>
        <Test url="/visit-and-tickets" />
        <Test url="/a-power-store" itemURL={item => `/a-power-store/${item.slug}`} />
        <Test url="/about-psa" />
        <Test url="/psa-collections" itemURL={item => `/psa-collections/${item.slug}`} />
        <Test url="/whats-on" />
        <Test url="/whats-on/exhibitions" itemURL={item => `/whats-on/exhibitions/${item.slug}`} />
        <Test url="/whats-on/activities" itemURL={item => `/whats-on/activities/${item.slug}`} />
        <Test url="/whats-on/news" itemURL={item => `/whats-on/news/${item.slug}`} />
    </Stack>
)