import React from 'react';
import { Text, Link, Box, FlexGrid, Responsive, SVGLoader } from 'components/Elements';
import { HorizontalScroll } from 'components/HorizontalScroll';

const ProgramLink = ({ title, slug, logo, ...props }) => (
    <Link
        to={`whats-on/programs/${slug}`}
        display="flex"
        alignItems="center"
        {...props}
    >
        {logo && (
            <Box
                height="1.15em"
                width="1.15em"
                marginRight="1"
            >
                <SVGLoader
                    url={logo}
                    fill="text"
                    position="absolute"
                    width="100%"
                    height="100%"
                />
            </Box>
        )}
        {!logo && <Box height='1.15em' />}
        <Text whiteSpace="nowrap" lineHeight="1">
            {title}
        </Text>
    </Link>
)

const ProgramListDesktop = ({ programs, ...props }) => (
    <FlexGrid gutter="2" spacing="2">
        {programs.map(program => (
            <div key={program.slug}>
                <ProgramLink
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="text"
                    paddingX="2"
                    paddingY="1"
                    {...program}
                />
            </div>
        ))}
    </FlexGrid>
)

const ProgramListMobile = ({ programs, ...props }) => (
    <HorizontalScroll fade gutter="3">
        {programs.map(program => (
            <div key={program.slug}>
                <ProgramLink {...program} />
            </div>
        ))}
    </HorizontalScroll>
)

export const ProgramList = props => <Responsive Components={[ProgramListMobile, null, ProgramListDesktop]} {...props} />