import React, { useState, useCallback, useRef, useMemo } from "react";
import styled from 'styled-components';
import { Button, FlexGrid } from 'components/Elements';
import { SearchIcon } from 'assets/svg/SearchIcon.svg';
import { X } from 'assets/svg/X.svg';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'components/LanguageProvider';
import debounce from 'lodash/debounce';

const StyledAutosizeInput = styled(AutosizeInput)`
    input {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        background: transparent;
        border: none;
        outline: 0;
    }
`

export const SearchInput = ({ initialValue, onChange }) => {
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef();
    const focus = useCallback(() => inputRef.current.focus(), [inputRef]);
    const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);
    const onInput = useCallback(
        e => {
            setValue(e.target.value);
            debouncedOnChange(e.target.value);
        },
        [setValue, debouncedOnChange]
    )
    const clear = useCallback(
        () => {
            setValue("");
            debouncedOnChange("");
        },
        [setValue, debouncedOnChange]
    )
    return (
        <Button as="div" selected={!!value} onClick={focus} py={0} cursor="text" hover={false}>
            <FlexGrid gutter={1} flexWrap="nowrap" alignItems="center" height="100%">
                <div>
                    {!!value && (
                        <X
                            width="12px"
                            height="12px"
                            strokeColor="background"
                            onClick={clear}
                            cursor="pointer"
                        />
                    )}
                    {!value && (
                        <SearchIcon strokeColor="text" />
                    )}
                </div>
                <StyledAutosizeInput
                    ref={inputRef}
                    type="text"
                    value={value || ""}
                    onChange={onInput}
                    placeholder={useTranslation("search")}
                />
            </FlexGrid>
        </Button>
    )
}