import React from "react";
import { useParams } from "react-router-dom";
import { ArtistsAndWorkPage } from "./ArtistsAndWorkPage";
import { Loader } from "../../components/Loader/Loader";
import { BlocksPage } from "./BlocksPage";

const ProgramPageContent = ({ data, pages }) => {
  switch (data.type) {
    case "blocks":
      return <BlocksPage data={data} pages={pages} />;
    case "artists":
      return <ArtistsAndWorkPage data={data} pages={pages} />;
    default:
      return `Unknown page type: ${data.type}`;
  }
};

export const ProgramPage = ({ pages }) => {
  const { slug, page } = useParams();
  return (
    <Loader
      url={`/whats-on/programs/${slug}/${page}`}
      pages={pages}
      Render={ProgramPageContent}
    />
  );
};

// export const ProgramPage = ({ programSlug, pages, currPage }) => {
//     const index = pages.findIndex(({ slug }) => slug === currPage);
//     const { t } = useTranslation();
//     const prev = index > 0
//         ? pages[index - 1]
//         : null;
//     const next = index < pages.length - 1
//         ? pages[index + 1]
//         : null;
//     return (
//         <Stack spacing={[6, 6, 10]}>
//             <Loader url={`whats-on/programs/${programSlug}/${currPage}`} Render={ProgramPageContent} />
//             <Box display="flex" justifyContent="space-between">
//                 <Box>
//                     {prev && (
//                         <Link to={`/whats-on/programs/${programSlug}/${prev.slug}`}>
//                             <Text.Small>{t("Previous")}</Text.Small>
//                             <Box display="flex" alignItems="center">
//                                 <Box as={ArrowLeft} height="0.8em" marginRight="0.5em" />
//                                 <Text.Body>{prev.title}</Text.Body>
//                             </Box>
//                         </Link>
//                     )}
//                 </Box>
//                 <Box>
//                     {next && (
//                         <Link to={`/whats-on/programs/${programSlug}/${next.slug}`}>
//                             <Text.Small>{t("Next")}</Text.Small>
//                             <Box display="flex" alignItems="center">
//                                 <Text.Body>{next.title}</Text.Body>
//                                 <Box as={ArrowRight} height="0.8em" marginLeft="0.5em" />
//                             </Box>
//                         </Link>
//                     )}
//                 </Box>
//             </Box >
//         </Stack>
//     );
// }
