import React from 'react';
import { SVG } from 'components/Elements';

export const QRCodeIcon = props => (
    <SVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 0H0V12H12V0ZM10.2857 1.71429H1.71429V10.2857H10.2857V1.71429Z" fill="black" />
        <rect x="3.42871" y="3.42859" width="5.14286" height="5.14286" fill="black" />
    </SVG>
)

QRCodeIcon.defaultProps = { fill: "text" }
