import React from "react";
import { Link, Box, Text } from 'components/Elements';
import { ArrowLeft } from 'assets/svg/Arrow.svg';

export const BackLink = ({ to, children, ...props }) => (
    <Link display="flex" alignItems="center" to={to} {...props}>
        <Box as={ArrowLeft} height="0.8em" marginRight="0.5em" />
        <Text.Small>
            {children}
        </Text.Small>
    </Link>
);
