import React from "react";
import { useParams } from 'react-router-dom';
import { Text, Stack, FlexGrid, Button } from 'components/Elements';
import { BackLink } from 'components/BackLink';
import { Nav, PageContainer } from 'components/Layouts/APowerStoreLayout';
import { Blocks, DetailsBlock } from 'components/Blocks'
import { Loader } from "components/Loader";
import { Carousel } from "components/Carousel";
import { useTranslation } from 'components/LanguageProvider';

const ProductContent = ({ data: { title, buyLabel, buyUrl, images, details, blocks } }) => (
    <Stack>
        <BackLink to="/publication">
            {useTranslation("aPowerStore.title")}
        </BackLink>
        <Stack spacing={[6, 6, 10]}>
            <FlexGrid spacing={2}>
                <Stack width={['100%', 3 / 12, null, 3 / 14]}>
                    <Text.Title>{title}</Text.Title>
                    {buyLabel && (
                        <Button as="a" variant="large" to={buyUrl} disabled={!buyUrl}>
                            {buyLabel}
                        </Button>
                    )}
                </Stack>
                <Stack width={['100%', 9 / 12, null, 11 / 14]} spacing={[6, 6, 10]}>
                    {images.length > 0 && <Carousel files={images} />}
                </Stack>
            </FlexGrid>
            <Stack spacing={[6, 6, 10]} width={[null, null, null, 12 / 14]}>
                <DetailsBlock details={details} />
                <Blocks blocks={blocks} />
            </Stack>
        </Stack>
    </Stack>
)

export const Product = () => (
    <>
        <PageContainer>
            <Loader
                url={`/a-power-store/${useParams().slug}`}
                Render={ProductContent}
            />
        </PageContainer>
        <Nav />
    </>
)
