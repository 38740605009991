import React from "react";
import { Button } from 'components/Elements';
import { ButtonGroup } from 'components/ButtonGroup'

import { SearchInput } from './SearchInput';

const toggle = (filterValue, value) => {
    if (Array.isArray(filterValue)) {
        return filterValue.includes(value)
            ? filterValue.filter(x => x !== value)
            : [...filterValue, value];
    } else {
        return filterValue === value ? undefined : value;
    }
}

export const Filter = ({ value, options, update }) => {
    return (
        <>
            {options.map(({ title, slug }) => (
                <div key={slug}>
                    <Button
                        selected={Array.isArray(value)
                            ? value.includes(slug)
                            : value === slug
                        }
                        onClick={() => update(toggle(value, slug))}
                    >{title}</Button>
                </div>
            ))}
        </>
    )
}

export const Filters = ({ scroll, options, values, search, update }) => (
    Object.entries(options).map(([key, options]) => (
        <ButtonGroup key={key} scroll={scroll}>
            {search && search[key] && (
                <div>
                    <SearchInput
                        initialValue={values[search[key]]}
                        onChange={value => update({ [search[key]]: value })}
                    />
                </div>
            )}
            <Filter
                value={values[key]}
                options={options}
                update={value => update({ [key]: value })}
            />
        </ButtonGroup>
    ))
)