import React from "react";
import { Box, Text, Stack, Image, HTML, Link } from "components/Elements";
import { Masonry } from "components/Masonry";
import { Route } from "react-router-dom";
import { ProgramPageLayout } from "./ProgramPageLayout";
import { useLocation, useParams } from "react-router-dom";
import { Error404 } from "../../components/Error404/Error404";
import { BioBlock } from "../../components/Blocks/BioBlock/BioBlock";
import { BlockLayout } from "../../components/Blocks/BlockLayout/BlockLayout";
import { ArrowLeft } from "../../assets/svg/Arrow.svg";

const ArtworkThumbnail = ({ artist, artwork }) => {
  const location = useLocation();
  return (
    <Link to={`${location.pathname}/${encodeURIComponent(artist.name)}`}>
      <Stack spacing={[1, 2]}>
        <Image {...artwork.image} />
        <Box>
          <Text fontSize={[1, null, null, null, null, 2]} fontWeight={500}>
            {artist.name}, {artist.country}
            <br />
            <Text fontStyle="italic">{artwork.title}</Text>
          </Text>
          <Text.Small>
            {artwork.medium}
            <br />
            {artwork.date}
          </Text.Small>
        </Box>
      </Stack>
    </Link>
  );
};

const ArtistsAndWorkIndexPage = ({ data, pages }) => {
  const thumbnails = data.artists
    .map(artist => artist.artworks.map(artwork => ({ artist, artwork })))
    .reduce((a, b) => a.concat(b), []);
  const columns = [1, 2, 3, 4];
  return (
    <ProgramPageLayout pages={pages}>
      <Box marginX={[-1, null, -2]}>
        <Masonry columns={columns}>
          {thumbnails.map(({ artist, artwork }, i) => (
            <Box paddingX={[1, null, 2]} key={i} paddingBottom={4}>
              <ArtworkThumbnail artist={artist} artwork={artwork} />
            </Box>
          ))}
        </Masonry>
      </Box>
    </ProgramPageLayout>
  );
};

const ArtistPage = ({ data }) => {
  const params = useParams();
  const artistName = decodeURIComponent(params.artist);
  const artist = data.artists.find(artist => artist.name === artistName);
  if (!artist) return <Error404 />;
  const { bio, artworks } = artist;
  const title = (
    <>
      <Text.Subheading fontWeight={500}>{artist.name}</Text.Subheading>
      <Text.Subheading>{artist.country}</Text.Subheading>
    </>
  );
  return (
    <Stack spacing={[6, 6, 10]}>
      <BioBlock title={title} body={bio?.body} image={bio?.image} />
      {artworks.map((artwork, i) => {
        const title = (
          <>
            <Text fontSize={[1, null, 2]} fontWeight={500} fontStyle="italic">
              {artwork.title}
            </Text>
            <Text.Small>
              {artwork.medium}
              <br />
              {artwork.date}
            </Text.Small>
          </>
        );
        return (
          <BlockLayout title={title} key={i}>
            <Stack>
              <Image {...artwork.image} />
              <HTML>{artwork.caption}</HTML>
            </Stack>
          </BlockLayout>
        );
      })}
      <BlockLayout>
        <Link to={`/whats-on/programs/${params.slug}/${params.page}`}>
          <Box display="flex" alignItems="center">
            <Box as={ArrowLeft} height="0.8em" marginRight="0.5em" />
            <Text.Body>{data.title}</Text.Body>
          </Box>
        </Link>
      </BlockLayout>
    </Stack>
  );
};

export const ArtistsAndWorkPage = ({ data, pages }) => (
  <>
    <Route path="/whats-on/programs/:slug/:page" exact>
      <ArtistsAndWorkIndexPage data={data} pages={pages} />
    </Route>
    <Route path="/whats-on/programs/:slug/:page/:artist" exact>
      <ArtistPage data={data} />
    </Route>
  </>
);
