import React from "react";
import { Route, useLocation } from 'react-router-dom';
import { PageContainer, SidebarContainer, Nav } from 'components/Layouts/MoreLayout';
import { Box, Text, Stack, NavLink, Link, Responsive, FlexGrid } from "components/Elements";
import { BackLink } from "components/BackLink";
import { Loader } from 'components/Loader';
import { RouteTransition } from 'components/RouteTransition';
import { useTranslator, LanguageToggle } from "components/LanguageProvider";
import { DarkModeToggle } from "components/DarkModeProvider";
import { Blocks } from "components/Blocks";
import { baseTheme } from 'styles/theme';

import { SocialIcons } from './SocialIcons';

import { Search } from './Search';
import { Newsletter } from './Newsletter';
import { MediaCenter } from './MediaCenter';
const BlocksPageContent = ({ data, ...props }) => <Blocks blocks={data.blocks} {...props} />
const BlocksPage = props => <Loader url={useLocation().pathname} Render={BlocksPageContent} {...props} />

const currentYear = new Date().getFullYear();

const routes = [{
    title: "more.searchPSA.title",
    path: "/more/search",
    Component: Search
}, {
    title: "more.membership.title",
    path: "/more/membership",
    Component: BlocksPage
}, {
    title: "more.newsletter.title",
    path: "/more/newsletter",
    Component: Newsletter
}, {
    title: "more.mediaCenter.title",
    path: "/more/media-center",
    Component: MediaCenter
}, {
    title: "more.contact.title",
    path: "/more/contact",
    Component: BlocksPage
}]

const Menu = React.memo(() => {
    const t = useTranslator();
    return (
        <Stack minHeight="100%" display="flex" flexDirection="column">
            <Stack spacing={2} flexGrow={1}>
                <Text.Title>{t("more.title")}</Text.Title>
                <div>
                    {routes.map(({ title, path }) => (
                        <Text.Subheading
                            as={NavLink}
                            key={path}
                            to={path}
                            display="block"
                            activeProps={{ fontWeight: 500 }}
                        >
                            {t(title)}
                        </Text.Subheading>
                    ))}
                </div>
            </Stack>
            <Stack spacing={1}>
                <div>
                    <Text.Small>© {currentYear} {t("more.copyright")}</Text.Small>
                    <a href={process.env.REACT_APP_ICP_URL} target="_blank" rel="noopener noreferrer">
                        <Text.Small>
                            {t("more.icp")}
                        </Text.Small>
                    </a>
                    <Text.Small>{t("more.credit")}</Text.Small>
                </div>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <SocialIcons />
                    <FlexGrid gutter={2}>
                        <div>
                            <DarkModeToggle height={baseTheme.navLinkHeight} fontSize={3} />
                        </div>
                        <div>
                            <LanguageToggle height={baseTheme.navLinkHeight} fontSize={3} />
                        </div>
                    </FlexGrid>
                </Box>
            </Stack>
        </Stack>
    )
})

const MoreMobile = React.memo(() => {
    const t = useTranslator();
    return (
        <RouteTransition>
            <Route path="/more" exact>
                <PageContainer>
                    <Menu />
                </PageContainer>
            </Route>
            {routes.map(({ title, path, Component }) => (
                <Route path={path} key={path}>
                    <PageContainer>
                        <Stack>
                            <Stack spacing={1}>
                                <BackLink to="/more">{t("more.title")}</BackLink>
                                <Text.Title>{t(title)}</Text.Title>
                            </Stack>
                            <Component />
                        </Stack>
                    </PageContainer>
                </Route>
            ))}
        </RouteTransition>
    )
})

const MoreDesktop = React.memo(() => (
    <>
        <SidebarContainer>
            <Menu />
        </SidebarContainer>
        <RouteTransition>
            {routes.map(({ title, path, Component }) => (
                <Route path={path} key={path}>
                    <PageContainer>
                        <Component />
                    </PageContainer>
                </Route>
            ))}
        </RouteTransition>
    </>
))

export const More = React.memo(() => (
    <>
        <Responsive Components={[MoreMobile, null, MoreDesktop]} />
        <Nav />
    </>
))