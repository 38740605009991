import { useLocation, useHistory } from 'react-router-dom';
import * as queryString from 'utils/queryString';
import mapValues from 'lodash/mapValues';

export const useQueryString = (defaults) => {
    const location = useLocation();
    const history = useHistory();
    const query = queryString.parse(location.search);
    const params = mapValues(defaults, (defaultValue, key) => {
        if (!(key in query)) return defaultValue;
        const value = query[key];
        if (Array.isArray(defaultValue) && !Array.isArray(value)) return [value];
        return value;
    })
    const updateParams = (newValues, replace) => {
        const prevQuery = queryString.parse(location.search);
        const nextQuery = { ...prevQuery, ...newValues };
        const prevQuerystring = queryString.stringify(prevQuery)
        const nextQuerystring = queryString.stringify(nextQuery)
        if (prevQuerystring === nextQuerystring) return;
        const url = location.pathname + "?" + nextQuerystring;
        if (replace) {
            history.replace(url);
        } else {
            history.push(url);
        }
    }
    // useEffect(
    //     () => {
    //         debugger
    //         updateParams(params);
    //         return () => {
    //             updateParams(mapValues(defaults, () => undefined))
    //         }
    //     },
    //     []
    // )
    return [params, updateParams];
};