import React from "react";
import { Box, Text, Stack, FlexGrid } from 'components/Elements';
import { Filters } from 'components/Filter';

const supportsPositionSticky = 'CSS' in window && 'supports' in window.CSS && window.CSS.supports("position", "sticky");

export const FilteredFeed2Columns = ({ title, filtersLoading, filterOptions, filterValues, updateFilters, stickyTop = [2, 2, 2, 4], search, children }) => (
    <FlexGrid spacing={2}>
        <Box
            width={['100%', null, 4 / 12, 3 / 12]}
            position={supportsPositionSticky ? 'sticky' : undefined}
            top={supportsPositionSticky ? stickyTop : undefined}
        >
            <Text.Title minHeight={[null, null, 10, 12]}>{title}</Text.Title>
            <Stack>
                {!filtersLoading && <Filters options={filterOptions} values={filterValues} update={updateFilters} search={search} />}
            </Stack>
        </Box>
        <Box width={['100%', null, 8 / 12, 9 / 12]} paddingTop={[null, null, 10, 12]}>
            {children}
        </Box>
    </FlexGrid>
)