import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { baseTheme } from "styles/theme";
import { GlobalCSS } from "styles/global";
import { Box } from "components/Elements";
import { LanguageProvider } from "components/LanguageProvider";
import { Analytics } from "components/Analytics";
import { Bars } from "components/Bars";
import { ThemeProvider } from "components/ThemeProvider";
import { DarkModeProvider } from "components/DarkModeProvider";
import { ColorOverrideProvider } from "components/ColorOverrideProvider";
import { RouteTransition } from "components/RouteTransition";
import { Error404 } from "components/Error404";

import en from "languages/en.json";
import cn from "languages/cn.json";

import { Splash } from "pages/Splash";
import { VisitAndTickets } from "pages/VisitAndTickets";
import { APowerStore } from "pages/APowerStore";
import { Product } from "pages/Product";
import { AboutPSA } from "pages/AboutPSA";
import { PSACollections } from "pages/PSACollections";
import { Artwork } from "pages/Artwork";
import { WhatsOn } from "pages/WhatsOn";
import { More } from "pages/More";

import { APITest } from "pages/APITest";

const languages = { en, cn };

const Background = props => (
  <Box
    position="fixed"
    backgroundColor="background"
    color="text"
    transition="background-color .5s"
    inset={0}
    {...props}
  />
);

export const App = () => (
  <LanguageProvider languages={languages} defaultLanguage="en">
    <ThemeProvider theme={baseTheme}>
      <DarkModeProvider>
        <ColorOverrideProvider>
          <GlobalCSS />
          <Background />
          <Router>
            <Analytics />
            <RouteTransition>
              <Route path="/" exact>
                <Splash />
              </Route>
              <Route path="/visit-and-tickets">
                <VisitAndTickets />
              </Route>
              <Route path="/publication" exact>
                <APowerStore />
              </Route>
              <Route path="/publication/:slug">
                <Product />
              </Route>
              <Route path="/about-psa">
                <AboutPSA />
              </Route>
              <Route path="/psa-collections" exact>
                <PSACollections />
              </Route>
              <Route path="/psa-collections/:slug">
                <Artwork />
              </Route>
              <Route path="/whats-on">
                <WhatsOn />
              </Route>
              <Route path="/more">
                <More />
              </Route>
              <Route path="/api-test">
                <APITest />
              </Route>
              <Route>
                <Error404 />
              </Route>
            </RouteTransition>
            <Bars />
          </Router>
        </ColorOverrideProvider>
      </DarkModeProvider>
    </ThemeProvider>
  </LanguageProvider>
);
