import React, { useEffect, useRef } from "react";
import { Box, Responsive, NavLink, Stack, Text } from "components/Elements";
import { BackLink } from "components/BackLink";
import { HorizontalScroll } from "components/HorizontalScroll";
import { useLocation } from "react-router-dom";
import {
  PageContainer,
  SidebarContainer,
} from "components/Layouts/WhatsOnLayout";

const PageLink = props => (
  <Text.Subheading
    as={NavLink}
    display="block"
    activeProps={{ fontWeight: 500 }}
    {...props}
  />
);

const HorizontalMenu = ({ pages }) => {
  return (
    <HorizontalScroll fade gutter={4}>
      {pages.map(({ title, url }) => (
        <div key={url}>
          <PageLink to={url} key={url} whiteSpace="nowrap">
            {title}
          </PageLink>
        </div>
      ))}
    </HorizontalScroll>
  );
};

const ProgramLayoutMobile = ({ pages, children, backLink, title }) => {
  const ref = useRef();
  const { pathname } = useLocation();
  useEffect(() => {
    ref.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <PageContainer ref={ref}>
      <Stack>
        <Stack spacing={1}>
          {backLink && <BackLink to={backLink.url}>{backLink.title}</BackLink>}
          <Text.Title>{title}</Text.Title>
          <HorizontalMenu pages={pages} />
        </Stack>
        {children}
      </Stack>
    </PageContainer>
  );
};

const ProgramLayoutDesktop = ({ pages, children, backLink, title, logo }) => (
  <>
    <SidebarContainer>
      <Stack
        spacing={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        minHeight="100%"
      >
        <Stack spacing={1}>
          {backLink && <BackLink to={backLink.url}>{backLink.title}</BackLink>}
          <Text.Title>{title}</Text.Title>
        </Stack>
        <Stack spacing={0} flexGrow={1}>
          {pages.map(({ title, url }) => (
            <PageLink to={url} key={url}>
              {title}
            </PageLink>
          ))}
        </Stack>
        {logo && <Box as="img" height={10} maxWidth="100%" src={logo} />}
      </Stack>
    </SidebarContainer>
    <PageContainer>{children}</PageContainer>
  </>
);

export const ProgramLayout = ({ ...props }) => (
  <Responsive
    Components={[ProgramLayoutMobile, null, ProgramLayoutDesktop]}
    {...props}
  />
);
