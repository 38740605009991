import React, { useCallback } from "react";
import { FlexGrid, Box, Stack, Text } from 'components/Elements';
import { useFeed, LOADING } from './useFeed';
import { Thumbnail } from 'components/Thumbnail';
import { ArrowUp, ArrowDown } from 'assets/svg/Arrow.svg'
import { useTranslation } from 'components/LanguageProvider';
import { useQueryString } from 'hooks/useQueryString';
import { Error } from 'components/Error';

const End = props => (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" height={10} {...props} />
)

const Prev = props => (
    <End as={Stack} spacing={1} cursor="pointer" {...props}>
        <ArrowUp />
        <Text.Small>{useTranslation("previous")}</Text.Small>
    </End>
)

const Next = props => (
    <End as={Stack} spacing={1} cursor="pointer" {...props}>
        <Text.Small>{useTranslation("next")}</Text.Small>
        <ArrowDown />
    </End>
)

// const Loading = () => <End><div>{useTranslation("loading")}</div></End>

export const Feed = ({
    baseUrl,
    params,
    initialPage = 0,
    itemsPerPage = 24,
    columns = [2, 3, 2, 3, 4],
    Item = Thumbnail,
    gridSpacing = 4,
    ...props
}) => {
    const [query, updateQuery] = useQueryString({ page: undefined })
    const page = query.page ? Math.max(Number(query.page), 1) - 1 : initialPage;
    const updatePage = useCallback(
        page => updateQuery({ page: page === 0 ? undefined : page + 1 }, true),
        [updateQuery]
    )
    const feed = useFeed({ baseUrl, params, initialPage: page, itemsPerPage, updatePage });
    const noResults = useTranslation("noResults");
    if (feed.error) return <Error>{feed.error.message}</Error>
    return (
        <Stack spacing={4} {...props}>
            {!feed.loadedInitial && page > 0 && <End />}
            {!feed.loadingPrev && feed.hasPrev && <Prev onClick={feed.prev} />}
            <FlexGrid spacing={gridSpacing}>
                {feed.max === 0 && <Box>{noResults}</Box>}
                {feed.items.map((item, i) => {
                    const loading = item === LOADING;
                    return (
                        <Box width={columns.map(c => 1 / c)} key={feed.key + '_' + (feed.from + i)}>
                            <Item loading={loading} {...(loading ? {} : item)} />
                        </Box>
                    )
                })}
            </FlexGrid>
            {!feed.loadingNext && feed.hasNext && <Next onClick={feed.next} />}
        </Stack>
    )
}