import React from "react";
import { useParams } from 'react-router-dom';
import { Box, Text, Stack, FlexGrid } from 'components/Elements';
import { BackLink } from 'components/BackLink';
import { Nav, PageContainer } from 'components/Layouts/PSACollectionsLayout';
import { Blocks, DetailsBlock } from 'components/Blocks'
import { Loader } from "components/Loader";
import { Carousel } from "components/Carousel";
import { useTranslation } from 'components/LanguageProvider';

const ArtworkContent = ({ data: { title, artist, images, collectionNumber, date, medium, details, blocks } }) => (
    <Stack>
        <BackLink to="/psa-collections">
            {useTranslation("psaCollections.title")}
        </BackLink>
        <Stack spacing={[6, 6, 10]}>
            <FlexGrid spacing={2}>
                <Box width={['100%', 3 / 12, null, 3 / 14]}>
                    <Text.Title>{title}</Text.Title>
                    <Text.Title fontWeight={200}>{artist}</Text.Title>
                    <Text.Title fontWeight={200}>#{collectionNumber}</Text.Title>
                </Box>
                {images.length > 0 && (
                    <Carousel width={['100%', 9 / 12, null, 11 / 14]} files={images} />
                )}
            </FlexGrid>
            <Stack width={[null, null, null, 12 / 14]}>
                <DetailsBlock details={[{
                    label: useTranslation("psaCollections.date"),
                    value: date
                }, {
                    label: useTranslation("psaCollections.medium"),
                    value: medium
                }, ...details]} />
                <Blocks blocks={blocks} />
            </Stack>
        </Stack>
    </Stack>
)

export const Artwork = () => (
    <>
        <PageContainer>
            <Loader
                url={`/psa-collections/${useParams().slug}`}
                Render={ArtworkContent}
            />
        </PageContainer>
        <Nav />
    </>
)
