import React, { useState, useMemo, useCallback } from "react";
import { Stack, Button } from 'components/Elements';
import { ButtonGroup } from 'components/ButtonGroup';
import { Calendar } from 'components/Calendar';
import { useTranslator } from 'components/LanguageProvider';
import { parse, format, isDate, isValid } from 'date-fns';
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'utils/date';
import { useLocalDateRangeFormatter } from 'hooks/useFormatDate';

export const dateStringFormat = "yyyy-MM-dd";
export const parseDate = date => {
    try {
        const parsed = parse(date, dateStringFormat, new Date());
        return isValid(parsed) ? parsed : null;
    } catch (e) {
        return null;
    }
}
export const stringifyDate = date => format(date, dateStringFormat)
export const datesEqual = (d1, d2) => stringifyDate(d1) === stringifyDate(d2);
export const dateRangesEqual = (range1, range2) => (
    datesEqual(range1[0], range2[0]) && datesEqual(range1[1], range2[1])
)

const timeSpan = (period, locale) => {
    const now = Date.now();
    switch (period) {
        case 'month':
            return [startOfMonth(now, locale), endOfMonth(now, locale)];
        case 'week':
            return [startOfWeek(now, locale), endOfWeek(now, locale)];
        case 'day':
            return [startOfDay(now, locale), endOfDay(now, locale)];
        default:
            throw new Error();
    }
};

const DatePreset = ({ value, preset, onChange, selected, children, ...props }) => {
    const onClick = useCallback(
        () => onChange(selected ? null : preset),
        [onChange, preset, selected]
    );
    return <Button onClick={onClick} selected={selected} {...props}>{children}</Button>
}

const parseValue = value => {
    if (!value) return null;
    const from = parseDate(value[0]);
    const to = parseDate(value[1]);
    if (from === null || to === null) return null;
    return [from, to];
}

export const DatePicker = ({ value, onChange }) => {
    const t = useTranslator();
    const locale = t("_dateLocale");
    const formatDateRange = useLocalDateRangeFormatter();
    const dateValue = parseValue(value);
    const presets = useMemo(
        () => ({
            [t("whatsOn.today")]: timeSpan("day", locale),
            [t("whatsOn.thisWeek")]: timeSpan("week", locale),
            [t("whatsOn.thisMonth")]: timeSpan("month", locale),
        }),
        [t, locale]
    )
    const [calendarOpen, setCalendarOpen] = useState(() => {
        return dateValue !== null && !Object.values(presets).find(preset => dateRangesEqual(preset, dateValue));
    });
    const update = useCallback(
        dates => {
            if (dates === null) {
                onChange(null);
            } else {
                if (isDate(dates)) dates = [dates, dates];
                onChange(dates.map(stringifyDate));
            }
        },
        [onChange]
    );
    const openCalendar = useCallback(
        () => {
            setCalendarOpen(true);
            const now = Date.now();
            update([now, now]);
        },
        [setCalendarOpen, update]
    );
    const closeCalendar = useCallback(
        () => {
            setCalendarOpen(false)
            update(null);
        },
        [setCalendarOpen, update]
    );
    const setPreset = useCallback(
        value => {
            setCalendarOpen(false)
            update(value);
        },
        [setCalendarOpen, update]
    );
    return (
        <Stack>
            <ButtonGroup scroll={[true, false]}>
                {Object.keys(presets).map(key => (
                    <div key={key}>
                        <DatePreset
                            value={dateValue}
                            preset={presets[key]}
                            selected={!calendarOpen && dateValue && dateRangesEqual(presets[key], dateValue)}
                            onChange={setPreset}
                        >
                            {key}
                        </DatePreset>
                    </div>
                ))}
                <div>
                    <Button onClick={calendarOpen ? closeCalendar : openCalendar} selected={calendarOpen}>
                        {calendarOpen && dateValue
                            ? formatDateRange(dateValue[0], dateValue[1], false)
                            : t("whatsOn.calendar")
                        }
                    </Button>
                </div>
            </ButtonGroup>
            {calendarOpen && <Calendar value={dateValue} onChange={update} />}
        </Stack>
    )
}