import polyfill from "./utils/polyfill/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "App";

const init = async () => {
  await polyfill();
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
};

init();
