import { useState, useLayoutEffect } from 'react';

export const useRect = ref => {
    const [rect, setRect] = useState(null);
    useLayoutEffect(() => {
        const update = () => ref.current && setRect(ref.current.getBoundingClientRect());
        update();
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [ref])
    return rect;
}