import React from "react";
import styled from 'styled-components';
import { Box, FlexGrid } from 'components/Elements';
import tinycolor from 'tinycolor2';

const transparentize = color => tinycolor(color).setAlpha(0).toRgbString();
const gradient = direction => ({ theme }) => (
    `linear-gradient( to ${direction}, ${theme.colors.background}, ${transparentize(theme.colors.background)})`
)
const LeftGradient = styled(Box)`
    background-image: ${gradient('right')};
`
const RightGradient = styled(Box)`
    background-image: ${gradient('left')};
`


export const HorizontalScroll = ({ children, fade, gutter = 1, ...props }) => (
    <Box mx={-2} {...props}>
        <Box overflowX="auto" width="100%">
            <Box pl={2} pr={fade ? 4 : 2} display="inline-block" >
                <FlexGrid flexWrap="nowrap" gutter={gutter}>
                    {children}
                </FlexGrid>
            </Box>
        </Box>
        {fade && (
            <>
                <LeftGradient
                    position="absolute"
                    top={0}
                    left={0}
                    height="100%"
                    transition="background-image .5s"
                    width={2}
                />
                <RightGradient
                    position="absolute"
                    top={0}
                    right={0}
                    height="100%"
                    transition="background-image .5s"
                    width={4}
                />
            </>
        )}
    </Box >
);
