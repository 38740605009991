import React from "react";
import { Text, Stack, Box } from 'components/Elements';
import { Filters } from 'components/Filter';


export const FilteredFeed1Column = ({ title, filtersLoading, filterOptions, filterValues, updateFilters, search, children }) => (
    <Stack spacing={6}>
        <Text.Title flexGrow={1}>{title}</Text.Title>
        <Stack spacing={2} visibility={filtersLoading ? 'hidden' : undefined}>
            <Filters scroll options={filterOptions} values={filterValues} update={updateFilters} search={search} />
        </Stack>
        <Box visibility={filtersLoading ? 'hidden' : undefined}>
            {children}
        </Box>
    </Stack>
)