import React from "react";
import { PageContainer, Nav } from 'components/Layouts/APowerStoreLayout';
import { FilteredFeed } from 'components/FilteredFeed';
import { ProductThumbnail } from 'components/Thumbnail';
import { useTranslation } from 'components/LanguageProvider';


export const APowerStore = () => (
    <>
        <PageContainer>
            <FilteredFeed
                title={useTranslation("aPowerStore.title")}
                // API is still a-power-store, not publication
                url="/a-power-store"
                initialFilters={{
                    // productType: undefined,
                    tags: [],
                    q: ""
                }}
                getFilterOptions={{
                    // productType: data => data.productTypes,
                    tags: data => data.tags
                }}
                search={{
                    tags: "q"
                }}
                Item={ProductThumbnail}
            />
        </PageContainer>
        <Nav />
    </>
)