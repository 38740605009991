import React from "react";
import { useParams } from 'react-router-dom';
import { Text, Stack, Image, Responsive } from 'components/Elements';
import { BackLink } from 'components/BackLink';
import { Loader } from "components/Loader";
import { Blocks } from 'components/Blocks';
import { Details } from "components/Details";
import { QRCode } from 'components/QRCode';
import { ActivityTypeLink } from "components/TypeLink";
import { Nav, PageContainer, SidebarContainer } from 'components/Layouts/WhatsOnLayout';
import { useTranslator } from 'components/LanguageProvider';
import { parseISO } from "utils/date";
import { useLocalDateRangeFormatter } from 'hooks/useFormatDate';

const ActivityInfo = ({ title, activityType, startDate, endDate, details, reservations }) => {
    const t = useTranslator();
    const formatDateRange = useLocalDateRangeFormatter();
    return (
        <Stack>
            <Stack spacing={1}>
                <BackLink to="/whats-on/activities">
                    {t("whatsOn.activities")}
                </BackLink>
                <Text.Title>{title}</Text.Title>
            </Stack>
            <ActivityTypeLink {...activityType} />
            <Details
                details={[
                    {
                        label: t("activity.time"),
                        value: `${formatDateRange(parseISO(startDate), parseISO(endDate))}`
                    },
                    reservations && {
                        label: t("activity.reservations"),
                        value: (
                            <>
                                <Text>{t("activity.followWeChat")}</Text>
                                <QRCode />
                            </>
                        )
                    },
                    ...details
                ].filter(Boolean)}
                width={[null, 1 / 2, '100%']}
                leftColumnWidth={1 / 3}
                rightColumnWidth={2 / 3}
            />
        </Stack>
    )
}

const ActivityContentMobile = ({ data }) => (
    <PageContainer>
        <Stack spacing={6}>
            <ActivityInfo {...data} />
            <Image {...data.image} />
            <Blocks blocks={data.blocks} />
        </Stack>
    </PageContainer>
)

const ActivityContentDesktop = ({ data }) => (
    <>
        <SidebarContainer>
            <ActivityInfo {...data} />
        </SidebarContainer>
        <PageContainer>
            <Blocks blocks={[
                { type: 'image', image: data.image },
                ...data.blocks
            ]} />
        </PageContainer>
    </>
)

const ActivityContent = props => <Responsive Components={[ActivityContentMobile, null, ActivityContentDesktop]} {...props} />

export const Activity = React.memo(() => (
    <>
        <Loader
            url={`/whats-on/activities/${useParams().slug}`}
            Render={ActivityContent}
        />
        <Nav />
    </>
))