import React from "react";
import { useParams } from 'react-router-dom';
import { Text, Stack, Responsive } from 'components/Elements';
import { BackLink } from 'components/BackLink';
import { Loader } from "components/Loader";
import { Blocks } from 'components/Blocks';
import { NewsTypeLink } from "components/TypeLink";
import { Nav, PageContainer, SidebarContainer } from 'components/Layouts/WhatsOnLayout';
import { useTranslation } from 'components/LanguageProvider';
import { parseISO } from "utils/date";
import { useLocalDateFormatter } from 'hooks/useFormatDate';

const NewsItemInfo = ({ title, newsType, date }) => {
    const formatDate = useLocalDateFormatter();
    return (
        <Stack>
            <BackLink to="/whats-on/news">
                {useTranslation("whatsOn.news")}
            </BackLink>
            <Text.Title fontWeight={300}>{title}</Text.Title>
            <NewsTypeLink {...newsType} />
            <Text.Small>{formatDate(parseISO(date))}</Text.Small>
        </Stack>
    )
}

const NewsItemContentMobile = ({ data }) => (
    <PageContainer>
        <Stack spacing={6}>
            <NewsItemInfo {...data} />
            <Blocks blocks={data.blocks} />
        </Stack>
    </PageContainer>
)

const NewsItemContentDesktop = ({ data }) => (
    <>
        <SidebarContainer>
            <NewsItemInfo {...data} />
        </SidebarContainer>
        <PageContainer>
            <Blocks blocks={data.blocks} />
        </PageContainer>
    </>
)

const NewsItemContent = props => <Responsive Components={[NewsItemContentMobile, null, NewsItemContentDesktop]} {...props} />

export const NewsItem = () => (
    <>
        <Loader
            url={`/whats-on/news/${useParams().slug}`}
            Render={NewsItemContent}
        />
        <Nav />
    </>
)