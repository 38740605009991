import React, { useCallback } from "react";
import { Responsive } from 'components/Elements';
import { Error } from 'components/Error';
import { useQueryString } from 'hooks/useQueryString';
import * as queryString from 'utils/queryString';
import { Feed } from 'components/Feed';
import mapValues from 'lodash/mapValues';
import { useAPIData } from 'hooks/useAPI';

import { FilteredFeed1Column } from './FilteredFeed1Column';
import { FilteredFeed2Columns } from './FilteredFeed2Columns';

export const FilteredFeed = ({ url, title, initialFilters, getFilterOptions, search, Item }) => {
    const { data: filterData, loading: filtersLoading, error: filterError } = useAPIData(queryString.url(url, { limit: 0 }))
    const filterOptions = filterData ? mapValues(getFilterOptions, fn => fn(filterData)) : [];
    const [query, updateQuery] = useQueryString({ ...initialFilters, page: undefined });
    const updateFilters = useCallback(
        values => updateQuery({ ...values, page: undefined }, false),
        [updateQuery]
    );
    if (filterError) return <Error {...filterError} />
    return (
        <Responsive
            Components={[FilteredFeed1Column, null, FilteredFeed2Columns]}
            title={title}
            filtersLoading={filtersLoading}
            filterOptions={filterOptions}
            filterValues={query}
            updateFilters={updateFilters}
            search={search}
        >
            <Feed
                baseUrl={url}
                params={query}
                initialPage={Number(query.page || 0)}
                Item={Item}
            />
        </Responsive>
    )
}
