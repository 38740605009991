import React from 'react';
import { Box, FlexGrid } from 'components/Elements';
import { WeChat } from 'assets/svg/WeChat.svg';
import { Baidu } from 'assets/svg/Baidu.svg';
import { Instagram } from 'assets/svg/Instagram.svg';
// import { Facebook } from 'assets/svg/Facebook.svg';

export const SocialIcons = () => (
    <FlexGrid flexWrap="nowrap" gutter={2}>
        <Box as="a" href={process.env.REACT_APP_WECHAT_URL} target="_blank" display="block">
            <WeChat />
        </Box>
        <Box as="a" href={process.env.REACT_APP_WEIBO_URL} target="_blank" display="block">
            <Baidu />
        </Box>
        <Box as="a" href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" display="block">
            <Instagram />
        </Box>
    </FlexGrid>
)