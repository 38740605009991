import React from "react";
import { Box, Text, FlexGrid, Stack, HTML, Image } from 'components/Elements';
import { Loader } from 'components/Loader';
import { Blocks } from 'components/Blocks';
import { PageContainer, Nav } from 'components/Layouts/AboutPSALayout';
import { useTranslation } from 'components/LanguageProvider';
import { BlockLayout, blockSpacing } from 'components/Blocks'

const AboutPSAContent = ({ data }) => (
    <Box py={[2, null, null, 4]}>
        <Stack spacing={blockSpacing}>
            <Text.Title>{useTranslation("aboutPSA.title")}</Text.Title>
            {data.image && <Image {...data.image} />}
            <Blocks blocks={data.blocks} />
            <BlockLayout title={data.history.title} stacked>
                <FlexGrid spacing={6}>
                    {data.history.items.map(({ image, title, body }, i) => (
                        <Stack key={i} spacing={2} width={['100%', 1 / 2]}>
                            <Image {...image} />
                            <Text.Body fontWeight={500}>{title}</Text.Body>
                            <HTML>{body}</HTML>
                        </Stack>
                    ))}
                </FlexGrid>
            </BlockLayout>
        </Stack>
    </Box>
)

export const AboutPSA = () => (
    <>
        <PageContainer>
            <Loader url="/about-psa" Render={AboutPSAContent} />
        </PageContainer>
        <Nav />
    </>
)