import React, { useLayoutEffect, useRef, Children } from "react";
import { Box } from "components/Elements";
import { useResponsiveProp } from "../../hooks/useBreakpoint";
import { resizeObserver } from "utils/observer";
import throttle from "lodash/throttle";

const masonryLayout = ({ numColumns, containerWidth, itemHeights }) => {
  const columnWidth = containerWidth / numColumns;
  const columns = Array(numColumns)
    .fill(0)
    .map((_, i) => ({ items: [], x: i * columnWidth, bottom: 0 }));
  itemHeights.forEach((height, i) => {
    const nextColumn = columns.reduce((shortest, column) =>
      column.bottom < shortest.bottom ? column : shortest
    );
    nextColumn.items.push({
      i,
      position: { x: nextColumn.x, y: nextColumn.bottom },
    });
    nextColumn.bottom += height;
  });
  return {
    containerHeight: columns.reduce((tallest, column) =>
      column.bottom > tallest.bottom ? column : tallest
    ).bottom,
    positions: columns.reduce((items, column) => {
      column.items.forEach(({ i, position }) => (items[i] = position));
      return items;
    }, []),
  };
};

const applyMasonry = (container, numColumns) => {
  const children = [...container.children];
  const { containerHeight, positions } = masonryLayout({
    numColumns,
    containerWidth: container.getBoundingClientRect().width,
    itemHeights: children.map(el => el.getBoundingClientRect().height),
  });
  container.style.height = containerHeight + "px";
  positions.forEach(({ x, y }, i) => {
    children[i].style.transform = `translate(${x}px, ${y}px)`;
  });
};

export const Masonry = ({ columns, children }) => {
  const ref = useRef();
  const currColumns = useResponsiveProp(columns);
  useLayoutEffect(() => {
    const container = ref.current;
    const elements = [container, ...container.children];
    const onResize = throttle(() => applyMasonry(container, currColumns), 100);
    elements.forEach(element => resizeObserver.observe(element, onResize));
    return () => {
      elements.forEach(element => resizeObserver.unobserve(element, onResize));
      onResize.cancel();
    };
  }, [currColumns]);
  return (
    <Box ref={ref}>
      {Children.map(children, (child, i) => (
        <Box
          position="absolute"
          top={0}
          left={0}
          width={columns.map(cols => (1 / cols) * 100 + "%")}
          key={i}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};
