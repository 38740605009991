import React, { useCallback } from "react";
import { Stack, Button, Link } from 'components/Elements';
import { ButtonGroup } from 'components/ButtonGroup';
import { DatePicker } from './DatePicker/DatePicker'
import { useTranslator } from 'components/LanguageProvider';
import { Filter, SearchInput } from 'components/Filter';
import { Route, useRouteMatch } from 'react-router-dom'
import pick from 'lodash/pick';
import * as queryString from 'utils/queryString';

const CategoryLink = ({ url, params, children }) => {
    const match = useRouteMatch(url);
    return (
        <Button
            as={Link}
            to={match ? '/whats-on' : queryString.url(url, params)}
            selected={match}
        >
            {children}
        </Button>
    )
}

export const WhatsOnFilters = ({ query, updateQuery, filterOptions, ...props }) => {
    const t = useTranslator();
    const dates = query.startDate && query.endDate
        ? [query.startDate, query.endDate]
        : undefined;
    const updateDates = useCallback(
        value => {
            if (value === null) {
                updateQuery({
                    startDate: undefined,
                    endDate: undefined
                })
            } else {
                const [startDate, endDate] = value;
                updateQuery({
                    startDate,
                    endDate
                })
            }
        },
        [updateQuery]
    )
    const commonParams = pick(query, 'tags', 'startDate', 'endDate', 'q');
    const updateActivityType = useCallback(
        activityType => updateQuery({ activityType }),
        [updateQuery]
    )
    const updateNewsType = useCallback(
        newsType => updateQuery({ newsType }),
        [updateQuery]
    )
    const updateTags = useCallback(
        tags => updateQuery({ tags }),
        [updateQuery]
    )
    const updateSearch = useCallback(
        q => updateQuery({ q }),
        [updateQuery]
    )
    return (
        <Stack width="100%" spacing={3} {...props}>
            <div>
                <ButtonGroup scroll={[true, true, false]}>
                    <div>
                        <CategoryLink url={"/whats-on/exhibitions"} params={commonParams}>
                            {t("whatsOn.exhibitions")}
                        </CategoryLink>
                    </div>
                    <div>
                        <CategoryLink url={"/whats-on/activities"} params={commonParams}>
                            {t("whatsOn.activities")}
                        </CategoryLink>
                    </div>
                    <div>
                        <CategoryLink url={"/whats-on/news"} params={commonParams}>
                            {t("whatsOn.news")}
                        </CategoryLink>
                    </div>
                </ButtonGroup>
            </div>
            <div>
                <Route path="/whats-on/activities">
                    <ButtonGroup scroll={[true, true, false]}>
                        <Filter
                            value={query.activityType}
                            options={filterOptions.activityType}
                            update={updateActivityType}
                        />
                    </ButtonGroup>
                </Route>
            </div>
            <Route path="/whats-on/news">
                <div>
                    <ButtonGroup scroll={[true, true, false]}>
                        <Filter
                            value={query.newsType}
                            options={filterOptions.newsType}
                            update={updateNewsType}
                        />
                    </ButtonGroup>
                </div>
            </Route>
            <div>
                <ButtonGroup scroll={[true, true, false]}>
                    <div>
                        <SearchInput initialValue={query.q} onChange={updateSearch} />
                    </div>
                    <Filter
                        value={query.tags}
                        options={filterOptions.tags}
                        update={updateTags}
                    />
                </ButtonGroup>
            </div>
            <DatePicker
                value={dates}
                onChange={updateDates}
            />
        </Stack>
    )
}