import React from 'react';
import { Stack, Link, Text } from 'components/Elements';
import { Form, FormInput, Submit, Checkbox, useForm } from 'components/Form';
import { useTranslator } from 'components/LanguageProvider';

export const Newsletter = () => {
    const t = useTranslator();
    const { success, error, busy, onSubmit } = useForm(process.env.REACT_APP_NEWSLETTER_URL);
    return (
        <Form onSubmit={onSubmit}>
            <Stack>
                {error && <Text.Body color="red">{error}</Text.Body>}
                {success && <Text.Body color="red">{t("more.newsletter.success")}</Text.Body>}
                <FormInput label={t("more.newsletter.fullName")} type="text" required name="name" id="name" />
                <FormInput label={t("more.newsletter.email")} type="email" required name="email" id="email" />
                <FormInput label={t("more.newsletter.occupation")} type="text" name="occupation" id="occupation" />
                <Checkbox
                    required
                    label={
                        <>
                            {t("more.newsletter.termsText")} <Link textDecoration="underline" to="/more/terms-of-use">{t("more.newsletter.termsLink")}</Link>
                        </>
                    }
                />
                <Submit disabled={busy}>
                    {t("more.newsletter.signUp")}
                </Submit>
            </Stack>
        </Form >
    )
}