import React, { useState, useMemo, useCallback } from 'react';
import { Stack, Input } from 'components/Elements';
import { Feed } from 'components/Feed';
import { SearchResultThumbnail } from 'components/Thumbnail'
import { useTranslation } from 'components/LanguageProvider';
import { useQueryString } from 'hooks/useQueryString';
import debounce from 'lodash/debounce';

export const Search = () => {
    const [query, updateQuery] = useQueryString({ q: "" });
    const [search, setSearch] = useState(query.q);
    const debouncedSetSearch = useMemo(
        () => debounce(setSearch, 200),
        [setSearch]
    )
    const onChange = useCallback(
        e => {
            const value = e.target.value;
            updateQuery({ q: value === "" ? undefined : value, page: undefined });
            debouncedSetSearch(value);
        },
        [updateQuery, debouncedSetSearch]
    );
    return (
        <Stack spacing={6}>
            <Input
                value={query.q || ""}
                onChange={onChange}
                placeholder={useTranslation('more.searchPSA.title')}
                fontSize={6}
            />
            {query.q && (
                <Feed
                    baseUrl={"/search"}
                    params={{ q: search }}
                    Item={SearchResultThumbnail}
                    gridSpacing={10}
                />
            )}
        </Stack>
    )
}