import { useReducer, useEffect, useCallback, useMemo } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useTranslation } from 'components/LanguageProvider';
import * as queryString from 'utils/queryString';

export const LOADING = "LOADING";

const initialState = {
    key: null,
    from: 0,
    to: 0,
    page: 0,
    itemsPerPage: 0,
    max: null,
    items: [],
    loadingInitial: false,
    loadedInitial: false,
    loadingPrev: false,
    loadingNext: false,
    error: null
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return {
                ...initialState,
                key: action.key,
                page: action.page,
                itemsPerPage: action.itemsPerPage,
                from: action.page * action.itemsPerPage,
                to: (action.page + 1) * action.itemsPerPage,
                items: Array(action.itemsPerPage).fill(LOADING),
                loadingInitial: true
            }
        case 'loadingPrev':
            const prevCount = Math.min(state.from, state.itemsPerPage);
            return {
                ...state,
                from: state.from - prevCount,
                items: [...Array(prevCount).fill(LOADING), ...state.items],
                loadingPrev: true
            }
        case 'loadingNext':
            const nextCount = state.max === null
                ? state.itemsPerPage
                : Math.min(state.max - state.to, state.itemsPerPage);
            return {
                ...state,
                to: state.to + nextCount,
                items: [...state.items, ...Array(nextCount).fill(LOADING)],
                loadingNext: true
            }
        case 'loadedPrev':
            if (action.key !== state.key) return state;
            return {
                ...state,
                max: action.max,
                items: state.items.map((item, i) => {
                    return i < action.items.length ? action.items[i] : item
                }),
                loadingPrev: false
            }
        case 'loadedInitial':
        case 'loadedNext':
            if (action.key !== state.key) return state;
            let items = state.items;
            if (state.to > action.max) {
                items = items.slice(0, action.max - state.to);
            }
            const offset = items.length - action.items.length;
            items = items.map((item, i) => {
                return i >= offset
                    ? action.items[i - offset]
                    : item
            });
            return {
                ...state,
                max: action.max,
                to: Math.min(state.to, action.max),
                items,
                loadingNext: action.type === 'loadedNext' ? false : state.loadingNext,
                loadingInitial: action.type === 'loadedInitial' ? false : state.loadingInitial,
                loadedInitial: action.type === 'loadedInitial' ? true : state.loadedInitial
            }
        case 'error':
            return {
                ...state,
                error: action.error
            }
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
}

export const useFeed = ({
    baseUrl,
    params,
    initialPage = 0,
    itemsPerPage = 12,
    updatePage
}) => {
    const key = useTranslation("_apiHeader") + "_" + queryString.url(baseUrl, params);
    const api = useAPI();
    const [state, dispatch] = useReducer(reducer, key, key => ({ ...initialState, key }));
    const load = useCallback(
        (offset, limit) => {
            const query = { ...params, offset, limit };
            const url = queryString.url(baseUrl, query);
            return api(url);
        },
        [api, baseUrl, params]
    )
    const prev = useCallback(
        () => {
            const key = state.key;
            dispatch({ type: 'loadingPrev' });
            updatePage(state.page - 1);
            const count = Math.min(state.from, itemsPerPage)
            load(state.from - count, count)
                .then(({ items, info }) => {
                    dispatch({ type: 'loadedPrev', items, max: info.count, key })
                })
                .catch(error => dispatch({ type: 'error', error }));
        },
        [state, itemsPerPage, load, updatePage]
    )
    const next = useCallback(
        () => {
            const key = state.key;
            dispatch({ type: 'loadingNext' });
            updatePage(state.page + 1);
            load(state.to, itemsPerPage)
                .then(({ items, info }) => {
                    dispatch({ type: 'loadedNext', items, max: info.count, key })
                })
                .catch(error => dispatch({ type: 'error', error }));
        },
        [state, load, itemsPerPage, updatePage]
    );
    useEffect(
        () => {
            if (key !== state.key || itemsPerPage !== state.itemsPerPage) {
                dispatch({
                    type: 'init',
                    key,
                    page: initialPage,
                    itemsPerPage
                });
                updatePage(initialPage);
                load(itemsPerPage * initialPage, itemsPerPage)
                    .then(({ items, info }) => {
                        dispatch({ type: 'loadedInitial', items, max: info.count, key })
                    })
                    .catch(error => dispatch({ type: 'error', error }));
            }
        },
        [key, state, load, itemsPerPage, initialPage, updatePage]
    )
    return useMemo(
        () => ({
            ...state,
            hasPrev: state.loadedInitial && state.from > 0,
            hasNext: state.loadedInitial && state.to < state.max,
            prev,
            next
        }),
        [state, prev, next]
    )
}