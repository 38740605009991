import React from "react";
import { Link, Box } from 'components/Elements';

export const Announcement = ({ title, slug, ...props }) => (
    <Link
        to={`/whats-on/news/${slug}`}
        display="flex"
        alignItems="baseline"
        fontSize={[1, null, null, null, 2]}
        {...props}
    >
        <Box
            width="1.5em"
            height="1.5em"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="text"
            borderRadius="50%"
            textAlign="center"
            lineHeight="1.55"
            fontSize="0.75em"
            marginRight="1"
            flexShrink="0"
            top="-1px"
        >!</Box>
        <span>
            {title}
        </span>
    </Link>
);