import React from "react";
import { useParams } from 'react-router-dom';
import { Box, Text, Stack, Image, ImageAutoFit, Responsive } from 'components/Elements';
import { BackLink } from 'components/BackLink';
import { Blocks } from 'components/Blocks';
import { Loader } from "components/Loader";
import { Details } from "components/Details";
import { QRCode } from 'components/QRCode';
import { Nav, PageContainer, SidebarContainer } from 'components/Layouts/WhatsOnLayout';
import { useTranslation, useTranslator } from 'components/LanguageProvider';
import { parseISO } from "utils/date";
import { useLocalDateRangeFormatter } from 'hooks/useFormatDate';
import { useColorOverride } from "components/ColorOverrideProvider";

const ExhibitionInfo = ({ title, poster, startDate, endDate, details, buyTicket }) => {
    const t = useTranslator();
    const formatDateRange = useLocalDateRangeFormatter();
    return (
        <Stack>
            <BackLink to="/whats-on/exhibitions">
                {useTranslation("whatsOn.exhibitions")}
            </BackLink>
            <Box width={[3 / 4, 1 / 2, '100%', '100%', 3 / 4]} >
                <Image {...poster} />
            </Box>
            <Text.Title>{title}</Text.Title>
            <Details
                details={[
                    {
                        label: t("exhibition.dates"),
                        value: `${formatDateRange(parseISO(startDate), parseISO(endDate))}`
                    },
                    buyTicket && {
                        label: t("exhibition.admission"),
                        value: (
                            <>
                                <Text>{t("exhibition.followWeChat")}</Text>
                                <QRCode />
                            </>
                        )
                    },
                    ...details
                ].filter(Boolean)}
                width={[null, 1 / 2, '100%']}
                leftColumnWidth={1 / 3}
                rightColumnWidth={2 / 3}
            />
        </Stack>
    )
}

const ExhibitionContentMobile = ({ data }) => (
    <PageContainer>
        <Stack spacing={6}>
            <ExhibitionInfo {...data} />
            <Image {...data.image} />
            <Blocks blocks={data.blocks} />
        </Stack>
    </PageContainer>
)

const ExhibitionContentDesktop = ({ data }) => (
    <>
        <SidebarContainer>
            <ExhibitionInfo {...data} />
        </SidebarContainer>
        <PageContainer>
            <Stack spacing={4}>
                <ImageAutoFit
                    cover
                    height={["calc(100vh - 166px)"]}
                    {...data.image}
                />
                <Blocks blocks={data.blocks} />
            </Stack>
        </PageContainer>
    </>
)

const ExhibitionContent = props => {
    useColorOverride(props.data.colors);
    return <Responsive Components={[ExhibitionContentMobile, null, ExhibitionContentDesktop]} {...props} />
}

export const Exhibition = React.memo(() => (
    <>
        <Loader
            url={`/whats-on/exhibitions/${useParams().slug}`}
            Render={ExhibitionContent}
        />
        <Nav />
    </>
))