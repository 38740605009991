import React from "react";
import { useParams, Redirect, Route } from "react-router-dom";
// import { PageContainer, SidebarContainer } from 'components/Layouts/WhatsOnLayout';
import { Loader } from "components/Loader";
import { RouteTransition } from "components/RouteTransition";
import { useColorOverride } from "components/ColorOverrideProvider";
import { useTranslation } from "components/LanguageProvider";
import { ProgramLayout } from "./ProgramLayout";
import { ProgramPage } from "./ProgramPage";

const ProgramContent = ({ data: { title, pages, colors, logo } }) => {
  const { slug } = useParams();
  useColorOverride(colors);
  const pageURLs = pages.map(page => ({
    title: page.title,
    url: `/whats-on/programs/${slug}/${page.slug}`,
  }));
  return (
    <ProgramLayout
      pages={pageURLs}
      backLink={{
        title: useTranslation("whatsOn.title"),
        url: "/whats-on",
      }}
      title={title}
      logo={logo}
    >
      <RouteTransition>
        <Route path="/whats-on/programs/:slug/:path+">
          <Route path="/whats-on/programs/:slug/:page">
            <ProgramPage pages={pageURLs} />
          </Route>
        </Route>
      </RouteTransition>
      <Route path="/whats-on/programs/:slug" exact>
        <Redirect to={pageURLs[0].url} />
      </Route>
    </ProgramLayout>
  );
};

export const Program = () => (
  <Loader
    url={`/whats-on/programs/${useParams().slug}`}
    Render={ProgramContent}
  />
);
