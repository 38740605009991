import React from "react";
import styled from 'styled-components/macro';
import { PageContainer } from 'components/Layouts/WhatsOnLayout';
import { Route } from 'react-router-dom';
import { RouteTransition } from 'components/RouteTransition';
import { SidebarContainer } from 'components/Layouts/WhatsOnLayout';
import { useTranslation } from 'components/LanguageProvider';
import { WhatsOnFilters } from './WhatsOnFilters';
import { WhatsOnOverview } from './WhatsOnOverview';
import { Announcement } from './Announcement';
import { Text, Stack, Box, Link, Responsive } from 'components/Elements';
import { ExhibitionThumbnail, ActivityThumbnail, NewsThumbnail } from 'components/Thumbnail'
import { Feed } from 'components/Feed';
import { Logo } from 'assets/svg/Logo.svg';
import { Error404 } from 'components/Error404';
import { useAPIData } from 'hooks/useAPI';
import { useQueryString } from 'hooks/useQueryString';
import { Error } from "components/Error";

const Footer = () => (
  <Stack spacing={2}>
      <Logo />
      <a href={process.env.REACT_APP_ICP_URL} target="_blank" rel="noopener noreferrer">
          <Text.Small>
              {useTranslation("more.icp")}
          </Text.Small>
      </a>
  </Stack>
)

const MembersBadge = styled(Link)`
    width: 7em;
    height: 7em;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-10deg);
`
MembersBadge.defaultProps = {
    backgroundColor: 'text',
    color: 'background',
    lineHeight: 'display',
    fontSize: [2, 2, 2, 3]
}

const SidebarLayout = ({ children, footer }) => (
    <Stack
        spacing={4}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        minHeight="100%"
    >
        {children}
        <Box flexGrow="1" display="flex" flexDirection="column" justifyContent="flex-end">
            {footer}
        </Box>
    </Stack>
)

const Exhibitions = props => <Feed baseUrl="/whats-on/exhibitions" Item={ExhibitionThumbnail} {...props} />
const Activities = props => <Feed baseUrl="/whats-on/activities" Item={ActivityThumbnail} {...props} />
const News = props => <Feed baseUrl="/whats-on/news" Item={NewsThumbnail} {...props} />

const WhatsOnIndexMobile = ({ title, data, query, updateQuery, filtersLoading, filtersError, filterOptions }) => {
    return (
        <PageContainer>
            <SidebarLayout footer={!filtersLoading && <Footer />}>
                <Text.Title>{title}</Text.Title>
                {filtersError && (
                    <Error>{filtersError.message}</Error>
                )}
                <WhatsOnFilters
                    query={query}
                    updateQuery={updateQuery}
                    filterOptions={filterOptions}
                    display={filtersLoading || filtersError ? 'none' : undefined}
                />
                {data && data.announcement && (
                    <Route path="/whats-on" exact>
                        <Announcement {...data.announcement} />
                    </Route>
                )}
                <Box width="100%" display={filtersLoading ? 'none' : undefined}>
                    <RouteTransition>
                        <Route key="/whats-on" path="/whats-on" exact>
                            <WhatsOnOverview
                                params={query}
                                programs={data && data.programs}
                                announcement={data && data.announcement}
                            />
                        </Route>
                        <Route key="/whats-on/exhibitions" path="/whats-on/exhibitions" exact>
                            <Exhibitions params={query} />
                        </Route>
                        <Route key="/whats-on/activities" path="/whats-on/activities" exact>
                            <Activities params={query} />
                        </Route>
                        <Route key="/whats-on/news" path="/whats-on/news" exact>
                            <News params={query} />
                        </Route>
                        <Route key="404">
                            <Error404 />
                        </Route>
                    </RouteTransition>
                </Box>
            </SidebarLayout>
        </PageContainer>
    )
}

const WhatsOnIndexDesktop = ({ title, data, query, updateQuery, filtersLoading, filtersError, filterOptions }) => (
    <>
        <SidebarContainer>
            <SidebarLayout footer={<Footer />}>
                <Link to="/whats-on">
                    <Text.Title>{title}</Text.Title>
                </Link>
                {filtersError && (
                    <Error>{filtersError.message}</Error>
                )}
                {!filtersLoading && !filtersError && (
                    <>
                        <WhatsOnFilters
                            query={query}
                            updateQuery={updateQuery}
                            filterOptions={filterOptions}
                        />
                        {data && data.announcement && <Announcement {...data.announcement} />}
                        {data && <MembersBadge as={Link} to={"/more/membership"}>{data.membership.label}</MembersBadge>}
                    </>
                )}
            </SidebarLayout>
        </SidebarContainer>
        <RouteTransition resetScroll>
            <Route path="/whats-on" exact>
                <PageContainer>
                    <WhatsOnOverview
                        params={query}
                        programs={data && data.programs}
                        announcement={data && data.announcement}
                    />
                </PageContainer>
            </Route>
            <Route path="/whats-on/exhibitions" exact>
                <PageContainer>
                    <Exhibitions params={query} />
                </PageContainer>
            </Route>
            <Route path="/whats-on/activities" exact>
                <PageContainer>
                    <Activities params={query} />
                </PageContainer>
            </Route>
            <Route path="/whats-on/news" exact>
                <PageContainer>
                    <News params={query} />
                </PageContainer>
            </Route>
            <Route>
                <PageContainer>
                    <Error404 />
                </PageContainer>
            </Route>
        </RouteTransition>
    </>
)

export const WhatsOnIndex = props => {
    const title = useTranslation("whatsOn.title");
    const { data, loading, error } = useAPIData("/whats-on");
    const [query, updateQuery] = useQueryString({
        tags: [],
        activityType: undefined,
        newsType: undefined,
        startDate: undefined,
        endDate: undefined,
        q: undefined
    });
    const filterOptions = {
        tags: (data && data.tags) || [],
        activityType: (data && data.activityTypes) || [],
        newsType: (data && data.newsTypes) || [],
    }
    return (
        <Responsive
            Components={[WhatsOnIndexMobile, null, WhatsOnIndexDesktop]}
            title={title}
            data={data}
            query={query}
            updateQuery={updateQuery}
            filtersLoading={loading}
            filtersError={error}
            filterOptions={filterOptions}
            {...props}
        />
    )
}