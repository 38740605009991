import React from 'react';
import { Stack, Text, Input } from '../Elements';

export const FormInput = ({
    value,
    onChange,
    debounce,
    label,
    id,
    type,
    name,
    required,
    placeholder,
    disabled,
    ...props
}) => {
    return (
        <Stack spacing={1} {...props}>
            {label && (
                <Text.Small as="label" display="block" htmlFor={id}>
                    {label}{required ? '*' : ''}
                </Text.Small>
            )}
            <Input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
            />
        </Stack>
    )
}