import React, { useRef } from "react";
import styled from 'styled-components/macro';
import { Stack, Text, Link, Box, FlexGrid, Responsive } from 'components/Elements';
import { HorizontalScroll } from 'components/HorizontalScroll';
import { Error } from 'components/Error';
import { useTranslator } from 'components/LanguageProvider';
import { ProgramList } from './ProgramList';
import { useAPIData } from 'hooks/useAPI';
import { useResponsiveProp } from 'hooks/useBreakpoint';
import { useRect } from 'hooks/useRect';
import { ExhibitionThumbnail, ActivityThumbnail, NewsThumbnail } from 'components/Thumbnail';
import * as queryString from 'query-string';
import tinycolor from 'tinycolor2';

const OverviewItemsMobile = ({ items, url, title, Item }) => (
    <HorizontalScroll>
        {items.map((item, i) => (
            <Box
                key={item.slug || i}
                flexShrink={0}
                width={["55vw", "28vw"]}
            >
                <Item {...item} />
            </Box>
        ))}
    </HorizontalScroll>
);

const transparentize = color => tinycolor(color).setAlpha(0).toRgbString();
const maxGradientHeight = 150;
const Gradient = styled(Box)`
    background-image: linear-gradient(to bottom, ${({ theme }) => transparentize(theme.colors.background)} 0%, ${({ theme }) => theme.colors.background} 100%);
`
const OverviewItemsDesktop = ({ items, url, Item, columns = [2, 3, 2, 3, 4] }) => {
    const currColumns = useResponsiveProp(columns);
    const ref = useRef();
    const rect = useRect(ref);
    const gradientHeight = (rect && items.length > currColumns) ? Math.min(rect.height, maxGradientHeight) : 0;
    const containerHeight = rect ? rect.height + gradientHeight + 'px' : 'auto';
    if (items.length === 0) return null;
    return (
        <Box height={containerHeight} overflow="hidden">
            <FlexGrid spacing={4}>
                {items.map((item, i) => (
                    <Box
                        ref={i === 0 ? ref : undefined}
                        key={i}
                        width={columns.map(x => 1 / x)}
                    >
                        <Item {...item} />
                    </Box>
                ))}
            </FlexGrid>
            <Gradient
                as={Link}
                to={url}
                position="absolute"
                bottom="0"
                width="100%"
                height={gradientHeight}
            />
        </Box>
    )
}

const SectionOverview = ({ url, items, params, title, Item, ...props }) => (
    <Stack spacing={2}>
        <Link to={url} display="block">
            <Text.Body>
                {title}
            </Text.Body>
        </Link>
        <Responsive
            Components={[OverviewItemsMobile, null, OverviewItemsDesktop]}
            items={items}
            url={url}
            Item={Item}
        />
    </Stack >
)

const loadingItems = Array(8).fill({ loading: true });
const useOverviewData = (url, params) => {
    const { data, loading, error } = useAPIData(url + '?' + queryString.stringify({ ...params, limit: 8 }))
    const items = (data && data.items) || loadingItems;
    return { items, loading, error };
}

export const WhatsOnOverview = ({ params, programs, announcement }) => {
    const t = useTranslator();
    const { items: exhibitionItems, loading: exhibitionLoading, error: exhibitionError } = useOverviewData("/whats-on/exhibitions", params);
    const { items: activityItems, loading: activityLoading, error: activityError } = useOverviewData("/whats-on/activities", params);
    const { items: newsItems, loading: newsLoading, error: newsError } = useOverviewData("/whats-on/news", params);
    if (exhibitionError) return <Error {...exhibitionError} />
    if (activityError) return <Error {...activityError} />
    if (newsError) return <Error {...newsError} />
    const loading = exhibitionLoading || activityLoading || newsLoading || programs === null;
    const gotNoItems = (
        !loading &&
        exhibitionItems.length === 0 &&
        activityItems.length === 0 &&
        newsItems.length === 0
    );
    return (
        <Stack spacing={[4, 4, 6]} width="100%">
            {gotNoItems && (
                <div>{t("noResults")}</div>
            )}
            {!loading && !gotNoItems && (
                <>
                    {programs && programs.length > 0 && <ProgramList programs={programs} />}
                    {exhibitionItems.length > 0 && (
                        <SectionOverview
                            key="exhibitions"
                            title={t("whatsOn.exhibitions")}
                            items={exhibitionItems}
                            url="/whats-on/exhibitions"
                            Item={ExhibitionThumbnail}
                        />
                    )}
                    {activityItems.length > 0 && (
                        <SectionOverview
                            key="activities"
                            title={t("whatsOn.activities")}
                            items={activityItems}
                            url="/whats-on/activities"
                            Item={ActivityThumbnail}
                        />
                    )}
                    {newsItems.length > 0 && (
                        <SectionOverview
                            key="news"
                            title={t("whatsOn.news")}
                            items={newsItems}
                            url="/whats-on/news"
                            Item={NewsThumbnail}
                        />
                    )}
                </>
            )}
        </Stack>
    )
}