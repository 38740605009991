import React from "react";
import { Route } from "react-router-dom";
import { Nav } from "components/Layouts/WhatsOnLayout";
import { RouteTransition } from "components/RouteTransition";
import { WhatsOnIndex } from "./WhatsOnIndex";

import { Exhibition } from "pages/Exhibition";
import { Activity } from "pages/Activity";
import { NewsItem } from "pages/NewsItem";
import { Program } from "pages/Program";

export const WhatsOn = React.memo(() => (
  <>
    <RouteTransition>
      <Route path="/whats-on/exhibitions/:slug">
        <Exhibition />
      </Route>
      <Route path="/whats-on/activities/:slug">
        <Activity />
      </Route>
      <Route path="/whats-on/programs/:slug">
        <Program />
      </Route>
      <Route path="/whats-on/news/:slug">
        <NewsItem />
      </Route>
      <Route path="/whats-on">
        <WhatsOnIndex />
      </Route>
    </RouteTransition>
    <Nav />
  </>
));
