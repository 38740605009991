import React, { useState, useCallback } from "react";
import { Text, Box } from 'components/Elements';
import { useTranslator } from 'components/LanguageProvider'
import { QRCodeIcon } from 'assets/svg/QRCodeIcon.svg';
import QRCodeImage from 'assets/images/QR code.jpeg';

export const QRCode = () => {
    const [shown, setShown] = useState(false);
    const toggle = useCallback(() => setShown(x => !x), [setShown]);
    const t = useTranslator();
    return (
        <>
            {shown && <Box as="img" src={QRCodeImage} width="100%" maxWidth="200px" />}
            <Box onClick={toggle} cursor="pointer" gutter={1} alignItems="baseline">
                {!shown && (
                    <Box marginRight="4px" marginBottom="-1px" display="inline-block">
                        <QRCodeIcon />
                    </Box>
                )}
                <Text display="inline" textDecoration="underline">{t(shown ? "hideQRCode" : "showQRCode")}</Text>
            </Box>
        </>
    )
};