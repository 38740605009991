import React, { useState, useCallback } from "react";
import { Stack, Box, Text } from 'components/Elements';
import { useFormatDate, useDateFormatter } from 'hooks/useFormatDate';
import { ArrowLeft, ArrowRight } from 'assets/svg/Arrow.svg';
import {
    addMonths,
    addDays,
    startOfMonth,
    getDaysInMonth,
    isSameMonth,
    getDay as getDayOfWeek,
} from 'date-fns';

const MonthPicker = ({ month, setMonth }) => {
    const prevMonth = useCallback(
        () => setMonth(month => addMonths(month, -1)),
        [setMonth]
    )
    const nextMonth = useCallback(
        () => setMonth(month => addMonths(month, 1)),
        [setMonth]
    )
    return (
        <Box display="flex">
            <Box as={ArrowLeft} height="0.8em" cursor="pointer" onClick={prevMonth} />
            <Text.Small textAlign="center" flexGrow="1">
                {useFormatDate(month, "MMMM y")}
            </Text.Small>
            <Box as={ArrowRight} height="0.8em" cursor="pointer" onClick={nextMonth} />
        </Box>
    )
}

const Cell = ({ i, count, selected, children, ...props }) => (
    <Box
        width={1 / 7 * 100 + '%'}
        paddingBottom={1 / 7 * 100 + '%'}
        borderRight={selected || i % 7 < 6 ? '1px solid' : 'none'}
        borderBottom={selected || i < count - 7 ? '1px solid' : 'none'}
        borderColor="text"
        backgroundColor={selected ? 'text' : 'background'}
        color={selected ? 'background' : 'text'}
        transition="color .2s, background-color .2s"
        {...props}
    >{children}</Box>
)

const Day = ({ date, i, count, selected, children, ...props }) => (
    <Cell i={i} count={count} selected={selected} {...props} >
        <Box
            position="absolute"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
        >{children}</Box>
    </Cell>
)

export const Calendar = ({ value, onChange }) => {
    const [month, setMonth] = useState(() => {
        const date = value ? new Date(value[0]) : new Date();
        return startOfMonth(date);
    });
    const format = useDateFormatter();
    // const weekdayFormatter = useDateFormatter("dd");
    // const dateFormatter = useDateFormatter("D");
    const days = getDaysInMonth(month);
    const from = -getDayOfWeek(month) + 1;
    const range = Math.ceil((days - from) / 7) * 7;
    const to = from + range;
    const dates = [];
    for (let i = from; i < to; i++) {
        dates.push(addDays(month, i));
    }
    return (
        <Stack>
            <MonthPicker month={month} setMonth={setMonth} />
            <Box display="flex" flexWrap="wrap">
                {dates.slice(0, 7).map((day, i) => (
                    <Text.Small
                        key={i}
                        width={1 / 7}
                        textAlign="center"
                        paddingBottom={1}
                    >{format(day, "EEEEE")}</Text.Small>
                ))}
                {dates.map((date, i) => {
                    if (isSameMonth(date, month)) {
                        return (
                            <Day
                                key={i}
                                count={dates.length}
                                date={date}
                                onClick={() => onChange([date, date])}
                                selected={value && (date >= value[0] && date <= value[1])}
                                i={i}
                            >
                                {format(date, "d")}
                            </Day>
                        )
                    } else {
                        return <Cell key={i} i={i} count={dates.length} />
                    }
                })}
            </Box>
        </Stack>
    )
}