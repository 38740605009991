import React from "react";
import { PageContainer, Nav } from 'components/Layouts/PSACollectionsLayout';
import { FilteredFeed } from 'components/FilteredFeed';
import { useTranslation } from 'components/LanguageProvider';
import { ArtworkThumbnail } from 'components/Thumbnail';

export const PSACollections = () => (
    <>
        <PageContainer>
            <FilteredFeed
                title={useTranslation("psaCollections.title")}
                url="/psa-collections"
                initialFilters={{
                    artworkType: undefined,
                    tags: [],
                    q: ""
                }}
                getFilterOptions={{
                    artworkType: data => data.artworkTypes,
                    tags: data => data.tags
                }}
                search={{
                    artworkType: 'q'
                }}
                Item={ArtworkThumbnail}
            />
        </PageContainer>
        <Nav />
    </>
)