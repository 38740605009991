import React, { useState, useCallback } from "react";
import styled from 'styled-components/macro'
import { Box, box, Text, Button } from 'components/Elements'

export const useForm = url => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);
    const onSubmit = useCallback(
        e => {
            e.preventDefault();
            if (busy) return;
            setSuccess(false);
            setError(null);
            setBusy(true);
            fetch(url, {
                method: "POST",
                body: new FormData(e.target)
            })
                .then(r => {
                    if (r.status === 200) {
                        setSuccess(true);
                        setBusy(false);
                    } else {
                        r.json().then(json => {
                            setError(json.error_description);
                            setBusy(false);
                        });
                    }
                })
        },
        [url, busy, setBusy, setError]
    )
    return { success, error, busy, onSubmit };
}

export const Form = ({ ...props }) => (
    <Box as="form" maxWidth="25em" {...props} />
)

export const Submit = ({ children, ...props }) => (
    <Button
        as="input"
        type="submit"
        variant="large"
        fontFamily="inherit"
        value={children}
        {...props}
    />
)

const CheckboxElement = styled(Box)`
    input:checked + & {
        ${({ theme, checkedProps }) => box({ theme, ...checkedProps })}
    }
`;
export const Checkbox = ({ label, id, name, required, ...props }) => (
    <Box as="label" display="block" cursor="pointer" htmlFor={id}>
        <Box as="input" type="checkbox" display="none" id={id} name={name} required={required} />
        <CheckboxElement
            display="inline-block"
            width="0.9em"
            height="0.9em"
            borderColor="text"
            borderWidth="1px"
            borderStyle="solid"
            marginRight={[1, 1, 2]}
            checkedProps={{
                backgroundColor: 'text'
            }}
        />
        <Text.Body as="span">
            {label}{required ? '*' : ''}
        </Text.Body>
    </Box>
)